html {
  background-color: #0b0b0b;
  -webkit-text-size-adjust: 100%;
}

body {
  position: fixed;
  display: block;
  background-color: #0b0b0b;
}

@media only screen and (max-width: 864px) {
  .webrcade-outer {
    max-height: 90vw;
  }
}

@media only screen and (max-width: 1152px) and (min-width: 864px) {
  .webrcade-outer {
    max-height: 75vw;
  }
}

@media only screen and (min-width: 1920px) {
  .webrcade-outer {
    max-height: 1080px;
  }
}

.details-header-nav {
  display: none;
}

/* Mobile sizing (experimental) */
@media only screen and (max-width: 950px) and (max-height: 400px) {
  .header-nav {
    display:none;
  }

  .details-header-nav {
    display: flex;
  }

  .app-details-content-container-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 2.7rem;
    padding-top: 0;
  }

  .app-details-content-container-description {
    min-height: 0px;
  }
}

.ok-accept-button {
  margin: 0 .6rem 0 0;
  padding: .2rem 1.2rem .2rem .8rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: .25rem;
  background: #ffe600;
  border: .2rem solid #ffe600;

  color: black;
  white-space: nowrap;
  cursor: pointer;
  outline: none;

  &>img {
    width: 1.8rem;
    height: 1.8rem;
  }

  &>div {
    padding-left: .25rem;
  }

  @media (hover) {
    &:hover:not(:focus) {
      opacity: .6;
      transition: 0.5s;
    }
  }

  &:focus {
    outline: none;
    background-color: red;
    border-color: red;
    color: white;
    transition: 0.25s;
  }

  &:active {
    outline: none;
  }
}

.negative-button {
  margin: 0 .6rem 0 0;
  padding: .2rem 1.2rem .2rem .8rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: .25rem;
  background: #ef4523;
  border: .2rem solid #ef4523;

  color: black;
  white-space: nowrap;
  cursor: pointer;
  outline: none;

  &>img {
    width: 1.8rem;
    height: 1.8rem;
  }

  &>div {
    padding-left: .25rem;
  }

  @media (hover) {
    &:hover:not(:focus) {
      opacity: .6;
      transition: 0.5s;
    }
  }

  &:focus {
    outline: none;
    background-color: red;
    border-color: red;
    color: white;
    transition: 0.25s;
  }

  &:active {
    outline: none;
  }
}

.play-save-button {
  margin: 0 .6rem 0 0;
  padding: .2rem 1.2rem .2rem .8rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: .25rem;
  background: #3f6bb3;
  border: .2rem solid #3f6bb3;

  color: black;
  white-space: nowrap;
  cursor: pointer;
  outline: none;

  &>img {
    width: 1.8rem;
    height: 1.8rem;
  }

  &>div {
    padding-left: .25rem;
  }

  @media (hover) {
    &:hover:not(:focus) {
      opacity: .6;
      transition: 0.5s;
    }
  }

  &:focus {
    outline: none;
    background-color: red;
    border-color: red;
    color: white;
    transition: 0.25s;
  }

  &:active {
    outline: none;
  }
}