.webrcade {
  display: flex;
  flex-direction: column;

  &-outer {
    margin: auto;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    max-width: 1920px;
    background-color: #1d1d1d;
    height: 100vh;
    width: 100vw;
    max-height: 56.25vw;
  }

  &-outer--hide {
    display: none;
  }

  &-app {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 22
  }
}

.header-nav {
  display: flex;
  flex-direction: row;

  &-left {
    flex-grow: 1;
    width: 4rem;
  }

  &-center>img {
    width: 6rem;

    padding: 0.5rem;
  }

  &-right {
    flex-grow: 1;
    width: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
}

.info-user {
  height: 60%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // Fix issue on Android where iframe is blue on exit
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: .3rem;
    padding: .1rem .4rem;
    opacity: .6;

    @media (hover) {
      &:hover {
        outline: none;
        background-color: #444;
        opacity: .7;
        transition: 0.4s;
        transform: scale(1.1);
      }

    }

    &:active {
      outline: none;
    }
  }

  &-head {
    height: 100%;
    display: inline-block;
    white-space: nowrap;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    // Fix issue on Android where iframe is blue on exit
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: .3rem;
    padding: .1rem .4rem;
    opacity: .6;

    @media (hover) {
      &:hover {
        outline: none;
        background-color: #444;
        opacity: .7;
        transition: 0.4s;
        transform: scale(1.1);
      }

    }

    &:active {
      outline: none;
    }
  }

  &-options {
    position: absolute;
    width: 10rem;
    bottom: calc(-100% - 0.5rem);
    right: -0.5rem;
    padding: 0.5rem;
    background-color: #ef4523;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;

    &.show {
      z-index: 200;
      opacity: 1;
    }

    &.hide {
      z-index: -1;
      opacity: 0;
    }

    button {
      width: 100%;
      background-color: transparent;
      color: #000000;
      padding: 0;
      margin: 0;
      border: 0;
      font-size: .9rem;
      text-align: start;
      cursor: pointer;
    }
  }

  &-loading {
    height: 100%;
    object-fit: contain;
    padding: 0.5rem;
  }
}

.settings-button {
  // Fix issue on Android where iframe is blue on exit
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: .3rem;
  margin-right: .5rem;
  padding: .1rem .4rem;
  opacity: .6;

  &>img {
    width: 2rem;
    height: 2rem;
  }

  &>div {}

  &:focus {
    // opacity: .8;
    background-color: #ef4523;
    opacity: .6;
    transition: 0.4s;
    transform: scale(1.1);
  }

  @media (hover) {
    &:hover {
      outline: none;
      background-color: #444;
      opacity: .7;
      transition: 0.4s;
      transform: scale(1.1);
    }

  }

  &:active {
    outline: none;
  }
}