.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 2rem;
  animation: fadein 1.5s;
  z-index: 5;  
  color: #e5e5e5;

  &-text {
    padding-top: 1rem;
    text-align: center;
    color: #bbb;
  }

  .container-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 24rem;
      height: 10rem;
      // transform: scale(0.5);
    }

    .loading-icon {
      width: 7rem;
      height: 7rem;
      // transform: scale(0.2);
    }
  }
}