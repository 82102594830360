.loading-call {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000e1;
  z-index: 600;

  img {
    position: relative;
    top: calc(50% - (10% / 2));
    left: calc(50% - (10% / 2));
    width: 10%;
    height: auto;
    object-fit: contain;

    @media only screen and (max-height: 950px) and (max-width: 400px) {
      top: calc(50% - (25% / 2));
      left: calc(50% - (25% / 2));
      width: 25%;
    }
  }
}