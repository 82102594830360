.preload-page {
  background-color: #0b0b0b;

  img {
    height: auto;
    width: 10%;
    position: absolute;
    top: calc(50% - (10% / 2));
    left: calc(50% - (10% / 2));
    object-fit: contain;

    @media only screen and (max-height: 950px) and (max-width: 400px) {
      width: 25%;
      top: calc(50% - (25% / 2));
      left: calc(50% - (25% / 2));
    }
  }
}