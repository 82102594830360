.prompt-screen {
  position: absolute;
  display: flex;
  z-index: 1000;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  align-items: center;

  &-inner {
    //height: 20rem;
    width: 100vw;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    background-color: rgba(25, 25, 25, .9);
    display: flex;
    padding: 3em;
    flex-direction: column;
    box-shadow: 0 0 2rem #151515;
    // box-shadow: 0 .4rem .8rem 0 rgba(0, 0, 0, 0.4), 0 .6rem 1rem 0 rgba(0, 0, 0, 0.39);
  }
}



